import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article class="section blogIndex" key={node.fields.slug}>
              <header showMenu={false} >
                <h3 style={{marginBottom: 10}}>
                  <Link style={{ boxShadow: `none` }} to={`/blog${node.fields.slug}`}>{title}</Link>
                </h3>
              </header>
              <section style={{display: "flex", alignItems: "center", justifyContent: "spaceBetween"}}>
                <Img sizes={node.frontmatter.featuredImage.childImageSharp.sizes} style={{
                  borderRadius: "50%",
                  width: "20vw",
                  height: "20vw",
                  maxWidth: "150px",
                  maxHeight: "150px",
                  marginRight: "20px",
                  flexShrink: 0
                }}/>
                <div>
                  <p class="blogDate">{node.frontmatter.date}</p>
                  <p dangerouslySetInnerHTML={{__html: node.frontmatter.description || node.excerpt}}/>
                </div>
              </section>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
